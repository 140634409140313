import { ApolloClient } from "@apollo/client";
import { DeepExtractType, DeepExtractTypeSkipArrays } from "ts-deep-extract-types";

import { paginatedRequestStreamShopifyGraphQL } from "./_utils";
import {
  CountryCode,
  CreateMediaInput,
  CurrencyCode,
  GetLocations,
  GetLocationsQuery,
  GetLocationsQueryVariables,
  GetSellingPlan,
  GetSellingPlanGroup,
  GetSellingPlanGroupProductVariants,
  GetSellingPlanGroupProductVariantsQuery,
  GetSellingPlanGroupProductVariantsQueryVariables,
  GetSellingPlanGroupProducts,
  GetSellingPlanGroupProductsQuery,
  GetSellingPlanGroupProductsQueryVariables,
  GetSellingPlanGroupQuery,
  GetSellingPlanGroupQueryVariables,
  GetSellingPlanGroupSellingPlans,
  GetSellingPlanGroupSellingPlansQuery,
  GetSellingPlanGroupSellingPlansQueryVariables,
  GetSellingPlanGroups,
  GetSellingPlanGroupsQuery,
  GetSellingPlanGroupsQueryVariables,
  GetSellingPlanQuery,
  GetSellingPlanQueryVariables,
  GetSellingPlans,
  GetSellingPlansQuery,
  GetSellingPlansQueryVariables,
  ProductCreate,
  ProductCreateMutation,
  ProductCreateMutationVariables,
  ProductInput,
  ProductJoinSellingPlanGroups,
  ProductJoinSellingPlanGroupsMutation,
  ProductJoinSellingPlanGroupsMutationVariables,
  ProductLeaveSellingPlanGroups,
  ProductLeaveSellingPlanGroupsMutation,
  ProductLeaveSellingPlanGroupsMutationVariables,
  ProductVariantJoinSellingPlanGroups,
  ProductVariantJoinSellingPlanGroupsMutation,
  ProductVariantJoinSellingPlanGroupsMutationVariables,
  ProductVariantLeaveSellingPlanGroups,
  ProductVariantLeaveSellingPlanGroupsMutation,
  ProductVariantLeaveSellingPlanGroupsMutationVariables,
  ProductVariantsBulkInput,
  ProductVariantsBulkUpdate,
  ProductVariantsBulkUpdateMutation,
  ProductVariantsBulkUpdateMutationVariables,
  SellingPlanFragmentFragment,
  SellingPlanGroupAddProductVariants,
  SellingPlanGroupAddProductVariantsMutation,
  SellingPlanGroupAddProductVariantsMutationVariables,
  SellingPlanGroupAddProducts,
  SellingPlanGroupAddProductsMutation,
  SellingPlanGroupAddProductsMutationVariables,
  SellingPlanGroupCreate,
  SellingPlanGroupCreateMutation,
  SellingPlanGroupCreateMutationVariables,
  SellingPlanGroupDelete,
  SellingPlanGroupDeleteMutation,
  SellingPlanGroupDeleteMutationVariables,
  SellingPlanGroupFragmentFragment,
  SellingPlanGroupInput,
  SellingPlanGroupRemoveProductVariants,
  SellingPlanGroupRemoveProductVariantsMutation,
  SellingPlanGroupRemoveProductVariantsMutationVariables,
  SellingPlanGroupRemoveProducts,
  SellingPlanGroupRemoveProductsMutation,
  SellingPlanGroupRemoveProductsMutationVariables,
  SellingPlanGroupUpdate,
  SellingPlanGroupUpdateMutation,
  SellingPlanGroupUpdateMutationVariables,
} from "./api";
import { SurfaceableError } from "../utils/SurfaceableError";

import { apolloShopifyMutation, apolloShopifyQuery, throws } from ".";

export type SellingPlanGroupFromGetSellingPlanGroupQuery = DeepExtractType<
  GetSellingPlanGroupQuery,
  ["sellingPlanGroup"]
>;
export type SellingPlanFromGetSellingPlanGroupQuery = DeepExtractTypeSkipArrays<
  GetSellingPlanGroupQuery,
  ["sellingPlanGroup", "sellingPlans", "edges", "node"]
>;

export interface ISellingPlanGroupProductsAndVariantsEmission {
  sellingPlanGroup: SellingPlanGroupFragmentFragment;
  sellingPlans?: SellingPlanFragmentFragment[];
  productIds?: string[];
  variantIds?: string[];
}

export interface ISellingPlanGroupsAndPlansEmission {
  sellingPlanGroup: SellingPlanGroupFragmentFragment;
  sellingPlans?: SellingPlanFragmentFragment[];
}

export interface LocationGroupZone {
  // prices depending on country
  methodDefinitions: MethodDefinition[]; // prices (pick one depending on conditions)
  zone: {
    name: string;
    countries: {
      code: {
        countryCode: CountryCode; // zone country codes
        restOfWorld: boolean;
      };
      name: string;
      provinces: {
        code: string;
        id: string;
        name: string;
      }[];
    }[];
  };
}
export interface MethodDefinition {
  // single price when ALL conditions are met
  active: boolean;
  methodConditions: MethodCondition[]; // conditions
  name: string;
  rateProvider: {
    // price itself
    price?: {
      currencyCode: CurrencyCode;
      amount: string;
    };
  };
}
type MethodCondition = PriceMethodCondition | WeightMethodCondition;

interface WeightMethodCondition {
  conditionCriteria: {
    unit: "POUNDS";
    value: number;
  };
  field: "TOTAL_WEIGHT";
  operator: OperatorConditions;
}
interface PriceMethodCondition {
  conditionCriteria: {
    currencyCode: CurrencyCode;
    amount: string;
  };
  field: "TOTAL_PRICE";
  operator: OperatorConditions;
}

export enum OperatorConditions {
  GREATER_THAN_OR_EQUAL_TO = "GREATER_THAN_OR_EQUAL_TO",
  LESS_THAN_OR_EQUAL_TO = "LESS_THAN_OR_EQUAL_TO",
}

// When we load nested lists (connections) for selling plan group and group is missing in the response,
// then we use this object to emulate empty connection
export const EMPTY_CONNECTION = Object.freeze({ pageInfo: { hasNextPage: false }, edges: [] });

export async function getAllSellingPlanGroupsProductsAndPlans(
  client: ApolloClient<object>,
  query?: string,
  onSellingPlanGroupFetch?: (group: ISellingPlanGroupsAndPlansEmission) => void
) {
  const data: ISellingPlanGroupProductsAndVariantsEmission[] = [];

  const res = await paginatedRequestStreamShopifyGraphQL<
    GetSellingPlanGroupsQuery,
    DeepExtractTypeSkipArrays<GetSellingPlanGroupsQuery, ["sellingPlanGroups", "edges", "node"]>
  >(
    groupCursor => queryShopifySellingPlanGroups(client, true, groupCursor, query),
    res => res.data.sellingPlanGroups,
    async paginatedSellingPlanGroupResult => {
      const sellingPlanGroups = paginatedSellingPlanGroupResult.data.sellingPlanGroups?.edges.map(e => e.node);
      if (!sellingPlanGroups) {
        return;
      }

      for (const sellingPlanGroup of sellingPlanGroups) {
        const sellingPlans: SellingPlanFragmentFragment[] = [];

        await paginatedRequestStreamShopifyGraphQL(
          planCursor => querySellingPlanGroupSellingPlans(client, sellingPlanGroup.id, 10, planCursor),
          res => res.data.sellingPlanGroup?.sellingPlans || EMPTY_CONNECTION,
          async res => {
            const sellingPlanNodes = res.data.sellingPlanGroup?.sellingPlans.edges.map(({ node }) => node) || [];
            for (const node of sellingPlanNodes) {
              sellingPlans.push(node);
            }
          }
        );

        const productMap: Record<string, boolean> = {};
        const variantIds: string[] = [];

        if (sellingPlanGroup.productVariantsCount?.count && sellingPlanGroup.productVariantsCount?.count > 0) {
          await paginatedRequestStreamShopifyGraphQL(
            variantCursor => querySellingPlanGroupVariants(client, sellingPlanGroup.id, 10, variantCursor),
            res => res.data.sellingPlanGroup?.productVariants || EMPTY_CONNECTION,
            async res => {
              const variantNodes = res.data.sellingPlanGroup?.productVariants.edges.map(({ node }) => node) || [];
              for (const node of variantNodes) {
                variantIds.push(node.id);
                productMap[node.product.id] = true;
              }
            }
          );
        }

        const sellingPlanGroupEmission = {
          sellingPlanGroup,
          productIds: Object.keys(productMap),
          variantIds,
          sellingPlans,
        };

        data.push(sellingPlanGroupEmission);

        if (onSellingPlanGroupFetch) {
          onSellingPlanGroupFetch(sellingPlanGroupEmission);
        }
      }
    },
    undefined, // startingCursor
    undefined, // additionalData
    false // parallelize, we set it to false to stop too much pressure on request rate
  );

  if (res.type === "error") {
    throw new SurfaceableError({ message: res.message, status: res.status });
  }

  return data;
}

export async function getSingleSellingPlanGroupProductsAndPlans(client: ApolloClient<object>, shopifyId: string) {
  try {
    const res = await queryShopifySellingPlanGroup(client, shopifyId);
    const sellingPlanGroup = res.sellingPlanGroup;
    if (!sellingPlanGroup) {
      throw new SurfaceableError({
        message: "Selling plan group not found",
        status: 404,
      });
    }

    const sellingPlans: SellingPlanFragmentFragment[] = [];
    await paginatedRequestStreamShopifyGraphQL(
      planCursor => querySellingPlanGroupSellingPlans(client, sellingPlanGroup.id, 10, planCursor),
      res => res.data.sellingPlanGroup?.sellingPlans || EMPTY_CONNECTION,
      async res => {
        const sellingPlanNodes = res.data.sellingPlanGroup?.sellingPlans.edges.map(({ node }) => node) || [];
        for (const node of sellingPlanNodes) {
          sellingPlans.push(node);
        }
      }
    );

    const productIds: string[] = [];
    await paginatedRequestStreamShopifyGraphQL(
      productCursor => querySellingPlanGroupProducts(client, sellingPlanGroup.id, 10, productCursor),
      res => res.data.sellingPlanGroup?.products || EMPTY_CONNECTION,
      async res => {
        const productNodes = res.data.sellingPlanGroup!.products.edges.map(({ node }) => node) || [];
        for (const node of productNodes) {
          productIds.push(node.id);
        }
      }
    );

    const variantIds: string[] = [];
    await paginatedRequestStreamShopifyGraphQL(
      variantCursor => querySellingPlanGroupVariants(client, sellingPlanGroup.id, 10, variantCursor),
      res => res.data.sellingPlanGroup?.productVariants || EMPTY_CONNECTION,
      async res => {
        const variantNodes = res.data.sellingPlanGroup?.productVariants.edges.map(({ node }) => node) || [];
        for (const node of variantNodes) {
          variantIds.push(node.id);
        }
      }
    );

    return {
      sellingPlanGroup,
      productIds,
      variantIds,
      sellingPlans,
    };
  } catch (error) {
    throw new SurfaceableError({ message: error.message, status: error.status });
  }
}

export async function getAllSellingPlanGroupsAndPlans(client: ApolloClient<object>, query?: string) {
  const data: ISellingPlanGroupProductsAndVariantsEmission[] = [];
  const res = await paginatedRequestStreamShopifyGraphQL<
    GetSellingPlanGroupsQuery,
    DeepExtractTypeSkipArrays<GetSellingPlanGroupsQuery, ["sellingPlanGroups", "edges", "node"]>
  >(
    groupCursor => queryShopifySellingPlanGroups(client, true, groupCursor, query),
    res => res.data.sellingPlanGroups,
    async paginatedSellingPlanGroupResult => {
      const sellingPlanGroups = paginatedSellingPlanGroupResult.data.sellingPlanGroups?.edges.map(e => e.node);
      if (!sellingPlanGroups) {
        return;
      }

      for (const sellingPlanGroup of sellingPlanGroups) {
        const sellingPlans: SellingPlanFragmentFragment[] = [];
        await paginatedRequestStreamShopifyGraphQL(
          planCursor => querySellingPlanGroupSellingPlans(client, sellingPlanGroup.id, 10, planCursor),
          res => res.data.sellingPlanGroup?.sellingPlans || EMPTY_CONNECTION,
          async res => {
            const sellingPlanNodes = res.data.sellingPlanGroup?.sellingPlans.edges.map(({ node }) => node) || [];
            for (const node of sellingPlanNodes) {
              sellingPlans.push(node);
            }
          }
        );

        data.push({
          sellingPlanGroup,
          sellingPlans,
        });
      }
    },
    undefined, // startingCursor
    undefined, // additionalData
    false // parallelize, we set it to false to stop too much pressure on request rate
  );

  if (res.type === "error") {
    throw new SurfaceableError({ message: res.message, status: res.status });
  }

  return data;
}

export const matchMethodDefinition = (
  lgz: LocationGroupZone,
  orderPriceInDollars: number,
  orderWeightInPounds: number | null
) => {
  return lgz.methodDefinitions.find(md => {
    return md.methodConditions.every(mc => {
      if (mc.field === "TOTAL_PRICE") {
        const requiredPrice = Number.parseFloat(mc?.conditionCriteria?.amount);
        if (Number.isNaN(requiredPrice)) {
          return false;
        }

        if (mc.operator === OperatorConditions.GREATER_THAN_OR_EQUAL_TO) {
          return orderPriceInDollars >= requiredPrice;
        } else if (mc.operator === OperatorConditions.LESS_THAN_OR_EQUAL_TO) {
          return orderPriceInDollars <= requiredPrice;
        }
      } else if (mc.field === "TOTAL_WEIGHT") {
        const requiredWeightInPounds = +mc.conditionCriteria.value;
        if (orderWeightInPounds === null || Number.isNaN(orderWeightInPounds)) {
          return false;
        }

        if (mc.operator === OperatorConditions.GREATER_THAN_OR_EQUAL_TO) {
          return orderWeightInPounds >= requiredWeightInPounds;
        } else if (mc.operator === OperatorConditions.LESS_THAN_OR_EQUAL_TO) {
          return orderWeightInPounds <= requiredWeightInPounds;
        }

        return false;
      }
    });
  });
};

export function querySellingPlans(ids: string[], client: ApolloClient<object>) {
  return apolloShopifyQuery<GetSellingPlansQuery, GetSellingPlansQueryVariables>(
    {
      query: GetSellingPlans,
      fetchPolicy: "no-cache",
      variables: {
        ids,
      },
    },
    client
  );
}

export function querySellingPlan(id: string, client: ApolloClient<object>) {
  return apolloShopifyQuery<GetSellingPlanQuery, GetSellingPlanQueryVariables>(
    {
      query: GetSellingPlan,
      fetchPolicy: "no-cache",
      variables: {
        id,
      },
    },
    client
  );
}

export function queryShopifySellingPlanGroups(
  client: ApolloClient<object>,
  forceRefetch = false,
  groupCursor?: string | null,
  query?: string
) {
  return apolloShopifyQuery<GetSellingPlanGroupsQuery, GetSellingPlanGroupsQueryVariables>(
    {
      query: GetSellingPlanGroups,
      fetchPolicy: forceRefetch ? "no-cache" : undefined,
      variables: {
        groupCursor,
        query,
      },
    },
    client
  );
}

export function querySellingPlanGroupSellingPlans(
  client: ApolloClient<object>,
  groupId: string,
  sellingPlanCount: number,
  sellingPlanCursor?: string | null
) {
  return apolloShopifyQuery<GetSellingPlanGroupSellingPlansQuery, GetSellingPlanGroupSellingPlansQueryVariables>(
    {
      query: GetSellingPlanGroupSellingPlans,
      variables: {
        id: groupId,
        count: sellingPlanCount,
        cursor: sellingPlanCursor,
      },
    },
    client
  );
}

export function querySellingPlanGroupProducts(
  client: ApolloClient<object>,
  groupId: string,
  productCount: number,
  productCursor?: string | null,
  bustCache?: boolean
) {
  return apolloShopifyQuery<GetSellingPlanGroupProductsQuery, GetSellingPlanGroupProductsQueryVariables>(
    {
      query: GetSellingPlanGroupProducts,
      fetchPolicy: bustCache ? "no-cache" : "cache-first",
      variables: {
        id: groupId,
        count: productCount,
        cursor: productCursor,
      },
    },
    client
  );
}

export function querySellingPlanGroupVariants(
  client: ApolloClient<object>,
  groupId: string,
  variantCount: number,
  variantCursor?: string | null,
  bustCache?: boolean
) {
  return apolloShopifyQuery<
    GetSellingPlanGroupProductVariantsQuery,
    GetSellingPlanGroupProductVariantsQueryVariables
  >(
    {
      query: GetSellingPlanGroupProductVariants,
      fetchPolicy: bustCache ? "no-cache" : "cache-first",
      variables: {
        id: groupId,
        count: variantCount,
        cursor: variantCursor,
      },
    },
    client
  );
}

export async function queryShopifySellingPlanGroup(client: ApolloClient<object>, id: string) {
  const sellingPlanGroupRes = await apolloShopifyQuery<
    GetSellingPlanGroupQuery,
    GetSellingPlanGroupQueryVariables
  >(
    {
      query: GetSellingPlanGroup,
      fetchPolicy: "no-cache",
      variables: {
        id,
      },
    },
    client
  );

  if (sellingPlanGroupRes.type === "error") {
    throw new Error(sellingPlanGroupRes.message);
  }

  if (!sellingPlanGroupRes.body.data.sellingPlanGroup) {
    throw new Error(`Couldn't get sellingPlanGroup from query`);
  }

  const sellingPlanGroup = sellingPlanGroupRes.body.data.sellingPlanGroup;

  return {
    sellingPlanGroup,
  };
}

export function mutationShopifyCreateSellingPlanGroup(
  input: SellingPlanGroupInput,
  client: ApolloClient<object>
) {
  return throws(
    apolloShopifyMutation<SellingPlanGroupCreateMutation, SellingPlanGroupCreateMutationVariables>(
      {
        mutation: SellingPlanGroupCreate,
        variables: {
          input,
        },
      },
      client
    )
  );
}

export function mutationShopifyUpdateSellingPlanGroup(
  id: string,
  input: SellingPlanGroupInput,
  client: ApolloClient<object>
) {
  return throws(
    apolloShopifyMutation<SellingPlanGroupUpdateMutation, SellingPlanGroupUpdateMutationVariables>(
      {
        mutation: SellingPlanGroupUpdate,
        variables: {
          id,
          input,
        },
      },
      client
    )
  );
}

export function mutationShopifyDeleteSellingPlanGroup(id: string, client: ApolloClient<object>) {
  return throws(
    apolloShopifyMutation<SellingPlanGroupDeleteMutation, SellingPlanGroupDeleteMutationVariables>(
      {
        mutation: SellingPlanGroupDelete,
        variables: {
          id,
        },
      },
      client
    )
  );
}

export function mutationShopifyProductJoinSellingPlanGroups(
  productId: string,
  sellingPlanGroupIds: string[],
  client: ApolloClient<object>
) {
  return apolloShopifyMutation<
    ProductJoinSellingPlanGroupsMutation,
    ProductJoinSellingPlanGroupsMutationVariables
  >(
    {
      mutation: ProductJoinSellingPlanGroups,
      variables: {
        productId,
        sellingPlanGroupIds,
      },
    },
    client
  );
}

export function mutationShopifyProductVariantJoinSellingPlanGroups(
  productVariantId: string,
  sellingPlanGroupIds: string[],
  client: ApolloClient<object>
) {
  return throws(
    apolloShopifyMutation<
      ProductVariantJoinSellingPlanGroupsMutation,
      ProductVariantJoinSellingPlanGroupsMutationVariables
    >(
      {
        mutation: ProductVariantJoinSellingPlanGroups,
        variables: {
          productVariantId,
          sellingPlanGroupIds,
        },
      },
      client
    )
  );
}

export function mutationShopifyProductLeaveSellingPlanGroups(
  productId: string,
  sellingPlanGroupIds: string[],
  client: ApolloClient<object>
) {
  return throws(
    apolloShopifyMutation<ProductLeaveSellingPlanGroupsMutation, ProductLeaveSellingPlanGroupsMutationVariables>(
      {
        mutation: ProductLeaveSellingPlanGroups,
        variables: {
          productId,
          sellingPlanGroupIds,
        },
      },
      client
    )
  );
}

export function mutationShopifyProductVariantLeaveSellingPlanGroups(
  productVariantId: string,
  sellingPlanGroupIds: string[],
  client: ApolloClient<object>
) {
  return throws(
    apolloShopifyMutation<
      ProductVariantLeaveSellingPlanGroupsMutation,
      ProductVariantLeaveSellingPlanGroupsMutationVariables
    >(
      {
        mutation: ProductVariantLeaveSellingPlanGroups,
        variables: {
          productVariantId,
          sellingPlanGroupIds,
        },
      },
      client
    )
  );
}

export function mutationSellingPlanGroupAddProducts(
  productIds: string[],
  sellingPlanGroupId: string,
  client: ApolloClient<object>
) {
  return apolloShopifyMutation<SellingPlanGroupAddProductsMutation, SellingPlanGroupAddProductsMutationVariables>(
    {
      mutation: SellingPlanGroupAddProducts,
      variables: {
        id: sellingPlanGroupId,
        productIds,
      },
    },
    client
  );
}

export function mutationSellingPlanGroupAddProductVariants(
  productVariantIds: string[],
  sellingPlanGroupId: string,
  client: ApolloClient<object>
) {
  return apolloShopifyMutation<
    SellingPlanGroupAddProductVariantsMutation,
    SellingPlanGroupAddProductVariantsMutationVariables
  >(
    {
      mutation: SellingPlanGroupAddProductVariants,
      variables: {
        id: sellingPlanGroupId,
        productVariantIds,
      },
    },
    client
  );
}

export function mutationSellingPlanGroupRemoveProducts(
  productIds: string[],
  sellingPlanGroupId: string,
  client: ApolloClient<object>
) {
  return apolloShopifyMutation<
    SellingPlanGroupRemoveProductsMutation,
    SellingPlanGroupRemoveProductsMutationVariables
  >(
    {
      mutation: SellingPlanGroupRemoveProducts,
      variables: {
        id: sellingPlanGroupId,
        productIds,
      },
    },
    client
  );
}

export function mutationSellingPlanGroupRemoveProductVariants(
  productVariantIds: string[],
  sellingPlanGroupId: string,
  client: ApolloClient<object>
) {
  return apolloShopifyMutation<
    SellingPlanGroupRemoveProductVariantsMutation,
    SellingPlanGroupRemoveProductVariantsMutationVariables
  >(
    {
      mutation: SellingPlanGroupRemoveProductVariants,
      variables: {
        id: sellingPlanGroupId,
        productVariantIds,
      },
    },
    client
  );
}

export function mutationShopifyProductCreate(
  input: ProductInput,
  media: CreateMediaInput,
  client: ApolloClient<object>
) {
  return throws(
    apolloShopifyMutation<ProductCreateMutation, ProductCreateMutationVariables>(
      {
        mutation: ProductCreate,
        variables: {
          input,
          media,
        },
      },
      client
    )
  );
}

export function mutationShopifyProductVariantsBulkUpdate(
  productId: string,
  input: ProductVariantsBulkInput[],
  client: ApolloClient<object>
) {
  return throws(
    apolloShopifyMutation<ProductVariantsBulkUpdateMutation, ProductVariantsBulkUpdateMutationVariables>(
      {
        mutation: ProductVariantsBulkUpdate,
        variables: {
          productId,
          variants: input,
        },
      },
      client
    )
  );
}

export function queryGetLocations(client: ApolloClient<object>) {
  return apolloShopifyQuery<GetLocationsQuery, GetLocationsQueryVariables>(
    {
      query: GetLocations,
    },
    client
  );
}
